@import url(https://fonts.googleapis.com/css?family=Luckiest+Guy);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:700,800);
/*!
 * Promo Buddy Site
 */
 /* Gothem Black Regular */
@font-face {
  font-family: 'Gotham Black';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Black'), url("/static/media/Gotham Black Regular.98d75753.woff") format('woff');
}
@font-face {
	font-family: 'GothamLight';
	src: url(/static/media/GothamLight.a514f7a0.eot);
	src: local('GothamLight'), url(/static/media/GothamLight.8e56cfb1.woff) format('woff'), url(/static/media/GothamLight.757008c3.ttf) format('truetype');
}
@font-face {
  font-family: 'Aller';
  font-style: normal;
  font-weight: normal;
  src: local('Aller'), url(/static/media/Aller_Rg.a644e18a.woff) format('woff');
}
@font-face {
  font-family: 'Aller Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Aller Bold'), url(/static/media/Aller_Bd.35639100.woff) format('woff');
}  
html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

body {
  font-family: 'Gothamlight';
  font-weight: 200;
}

a {
  color: #fdcc52;
  transition: all .35s;
}

a:hover, a:focus {
  color: #fcbd20;
}

hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
  border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gotham Black';
  letter-spacing: 1px;
}
.text-bold {
  font-weight: 700
}

.text-small {
  font-size: 0.9rem
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}
b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
blockquote {
  background: #eee;
  border-radius: 5px;
}

blockquote p {
  padding: 15px;
}
p {
  font-size: 14px;
  line-height: 1.5; 
  margin: 0 0 1rem 0;
}
table {
  width:100%;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 15px;
  text-align: left;
}
table tr:nth-child(even) {
  background-color: #eee;
}
table tr:nth-child(odd) {
 background-color: #fff;
}
table th {
  background-color: black;
  color: white;
}
section {
  padding: 45px 0;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1380px;
  }
}
#mainNav {
  border-color: rgba(34, 34, 34, 0.05);
  background-color: white;
  transition: all .35s;
  font-weight: 200;
  letter-spacing: 1px;
}

#mainNav .navbar-brand {
  color: #000000;
  font-weight: 600;
  font-family: 'Luckiest Guy', cursive;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 2rem;
  width: 150px;
  line-height: 1.6rem;
  white-space: normal;
}

#mainNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #222222;
}
#mainNav .navbar-nav > li {
   margin-left: 8px;
}
#mainNav .navbar-nav > li > a {
  font-size: 11px;
  font-family: 'Aller Bold';
  letter-spacing: 2px;
  text-transform: uppercase;
}

#mainNav .navbar-nav > li > a.active {
  color: #e74c3c !important;
  background-color: transparent;
}

#mainNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#mainNav .navbar-nav > li > a,
#mainNav .navbar-nav > li > a:focus {
  color: #222222;
}

#mainNav .navbar-nav > li > a:hover,
#mainNav .navbar-nav > li > a:focus:hover {
  color: #fdcc52;
}

@media (min-width: 992px) {
  #mainNav {
    border-color: transparent;
    background-color: #ebebeb;
  }
  #mainNav .navbar-nav > li > a,
  #mainNav .navbar-nav > li > a:focus {
    color: #000000;
    border: 1px solid #ffffff;
    border-radius: 8px;
  }
  #mainNav .navbar-nav > li > a:hover,
  #mainNav .navbar-nav > li > a:focus:hover {
    color: #e7ca2b;
  }
  #mainNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);    
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #222222;
  }
  #mainNav.navbar-shrink .navbar-brand:hover, #mainNav.navbar-shrink .navbar-brand:focus {
    color:#343a40;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #222222;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a:hover,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #fdcc52;
  }
}

header.masthead {
  position: relative;
  width: 100%; 
  color: white;
  height: 60%;
  /*background: url("/img/banner-img.jpg"), #e74c3c;
  background: url("/img/banner-img.jpg"), -webkit-gradient(linear, right top, left top, from(#e74c3c, #fff115));
  background: url("/img/banner-img.jpg"), linear-gradient(to left, #e74c3c, #fff115);  
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%; */
}

header.masthead  img{
  width: 100%;
  max-width: 100%;
  max-height: calc(100vh - 150px);
}

header.masthead .container {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
}

header.masthead .header-content {
  max-width: 500px;
  text-align: center;
}

header.masthead .header-content h1 {
  font-size: 1.5rem;
}

header.masthead .header-content p {
  font-family: 'GothamLight';
  font-size: 1rem;
}

@media (min-width: 992px) {
 
  header.masthead .header-content h1 {
    font-size: 3rem;
  }
  header.masthead .device-container {
    max-width: 325px;
  }
}

section.apply-now-section {
  padding: 0px;
  position: relative;
  clear: both;
}
section.apply-now-section .row:before {
  content: "";
  position: absolute;
  background: #fff115;
  width: 50%;
  left: 0px;
  height: 100%;
  z-index: -5555;
}
section.apply-now-section .row:after {
  content: "";
  position: absolute;
  background: #e2e2e2;
  width: 50%;
  right: 0px;
  height: 100%;
  z-index: -5555;
}
section.apply-now-section .left-colm{
  background-color: #fff115;
  padding: 5px 15px 5px 0px;
}

section.apply-now-section .right-colm{
  background-color: #e2e2e2;
  padding: 5px 0px 5px 15px;
}

section.apply-now-section .apply-now-txt {
  width: calc(100% - 140px);
  float: left;
}

section.apply-now-section .apply-now-txt h2 {
  font-size: 2rem;
  margin-bottom: 0px;
}
section.apply-now-section .apply-now-txt p {
  font-size: 1rem;
  margin: 0;
}
section.apply-now-section .apply-now-txt .section-heading {
   padding: 0px;
   line-height: 32px;
}
section.apply-now-section .apply-now-badges {
  float: left;
  padding:0; 
  text-align: right;  
}

@media (max-width: 480px) {
  section.apply-now-section .apply-now-txt,
  section.apply-now-section .apply-now-badges {
    width: 100%;   
    text-align: center;
    padding: 1rem 0px;
  }
  section.apply-now-section .apply-now-txt h2 {
    font-size: 1rem;
  }
  header.masthead .container {   
    top: 15%;    
  }
}

.section-heading {
  margin-bottom: 1rem;
  display: inline-block;
  padding-top: 0;
  padding-left: 10px;
}

section.promotions .card-resturant-food-inners {
   position: relative;
}

section.promotions .card-resturant-food-outers {
  padding: 1.5rem;
  float: left;
  width: 100%;
  height: 100%;
}

section.promotions .promotion-item-colm {
  margin-bottom: .65rem!important;
}

section.promotions .promotion-item-colm:hover .promotion-item.card {
  background: #ffffff;
  box-shadow: 5px 3px 8px 1px #d2d2d2;
}

section.promotions .card-resturant-food-inners .promotion-item-tags {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  padding: 6px;
}

.promotion-item-tags span.tag {
  background-color: #000000;
  color: #ffffff;
  padding: 2px 6px;
  font-size: 10px;
  font-family: 'Aller Bold';
  text-transform: uppercase;
  display: block;
} 

.promotion-item-tags {
  position: absolute;
  top: 2px;
  left: 2px;
}
section.promotions .card-resturant-food-inners img.resturant-logo{
  width: 100%;
  height: 225px;
}

section.promotions .card-resturant-food-outers .btn-promo-code img{
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.section-heading h2 {
  margin-top: 0;
  padding: 6px;
  color: #222222;
  font-family: 'Luckiest Guy', cursive;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 2rem;
}

section.promotions .promotion-item {
   text-align: center;
}

section.promotions .card-resturant-logo-img {
  padding: 0.6rem;
  padding-top:25px;
}

section.promotions .promotion-item.card {
  background: #ffffff;   
  border-radius: 0px;  
  transition: box-shadow 0.3s ease-in-out;
}
section.promotions .promotion-item h3 {
  font-size: 1.8rem;
  color: #181818;
  padding: 6px;
  margin: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
section.restaurants .restaurant-item-colm {
  margin-bottom: 10px;
}
section.restaurants .restaurant-item-colm .card {
  background-color: #f7f7f7;
  border: 2px solid #ffffff;
}
section.restaurants .restaurant-item-colm .card:hover {
  background-color: #ffffff;
  border: 2px solid #f7f7f7; 
  box-shadow: 4px 3px 7px -1px #c3c1c1;
}
section.restaurants .prmobudy-resturant-left-colm {
  position: relative;   
} 
header.secondaryhead{
  float: left;
  width: 100%;
  background: #000000;
  color: #ffff;
  text-align: center;
}
header.secondaryhead .header-content{ 
  padding: 1rem;
}
@media (min-width: 992px) {

 /* section.restaurants .restaurant-item-colm .prmobudy-resturant-left-colm:before {
    content: '';
    position: absolute;
    border-top: 87px solid #f7f7f7;
    border-left: 52px solid transparent;
    top: 0px;
    right: -6px; 
  }

  section.restaurants .restaurant-item-colm .prmobudy-resturant-left-colm:after {
    content: '';
    position: absolute;
    border-bottom: 90px solid #f7f7f7;
    border-left: 52px solid transparent;
    bottom: 0px;
    right: -6px; 
  }
  
  span.image-circle-cutter {
    background: #f7f7f7;
    position: absolute;
    right: -20px;
    top: 40%;
    width: 36px;
    height: 36px;
    border-radius: 100px;
  }
  section.restaurants .restaurant-item-colm .card:hover .prmobudy-resturant-left-colm:before {
    border-top: 87px solid #ffffff;
  }
  section.restaurants .restaurant-item-colm .card:hover .prmobudy-resturant-left-colm:after {
    border-bottom: 90px solid #ffffff;
  }
  section.restaurants .restaurant-item-colm .card:hover span.image-circle-cutter {
    background: #ffffff;
  }*/
}
section.restaurants .prmobudy-resturant-left-colm img.resturant-img {
  width: 100%;
  height: 100%;
  min-height: 230px;
}

section.restaurants .prmobudy-resturant-left-colm img.resturant-log {
  position: absolute;
  top: 40%;
  background: #fff;
  border-radius: 4px;
  padding: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

section.restaurants  .restaurant-item .card-title {
  font-size: 19px;
  text-transform: capitalize;
}

section.restaurants  .restaurant-item .card-title .res_name {
  color: #222222;
  text-decoration: none;
}

section.restaurants  .restaurant-item .card-title .res_name:hover  {
  color: #fdcc52;
  text-decoration: none;
}

section.restaurants  .restaurant-item .prmobudy-rf-categories,
section.restaurants  .restaurant-item .prmobudy-rf-locations {
   text-transform: capitalize;
}

section.restaurants .prmobudy-resturant-right-colm {
  padding: 10px;
}
.card-prmobudy-resturant-outers .btn {
  margin-right: 4px;
  width: 50%;
  font-size: 1rem;
  letter-spacing: inherit;
}

footer{
  background-color: #000000;
  padding: 3rem 0px 0px;
  color: #ffffff;
  float: left;
  width: 100%;
}	 
footer .container {
  padding-bottom: 3rem;
}
footer .brand-colm {
  margin-top: 6px;
}
footer .brand-logo {
  color: #ffffff;
  font-weight: 600;
  font-family: 'Luckiest Guy', cursive;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 2rem;
  width: 150px;
  line-height: 1.6rem;
  white-space: normal;
  margin-bottom: 10px;
}  
footer ul {
  margin-left: 0;
  padding-left: 0;
  overflow: hidden;
}    

footer .nav-header {
  color: #FFF;
  padding: 10px 0px;
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 3px;
}

ul.footer-nav li,
ul.footer-social li { 
 list-style: none;
}

ul.footer-nav li p ,  ul.footer-nav li a {
 color:#FFF;
 letter-spacing: 2px;
 text-decoration:none;
 font-size:14px;
}
footer .social-colm h6 {
  margin-top: -6px;
  font-size: 14px;
  margin-right: 10px;
  font-family: 'Open Sans', sans-serif;
}
.footer-social li {
 float:left;
 margin-right: 10px;
}

.footer-copyright-container {
  background: #f5f5f5;
  color: #000000;
  text-align: center;
  padding: 15px;
}
.footer-copyright-container p {
  margin: 0px; 
}
.bg-primary {
  background: #f5f5f5;
  background: linear-gradient(#f5f5f5, #f5f5f5);
}
.bg-secondary {
  background: #ffffff;
  background: linear-gradient(#ffffff, #ffffff);
}
.text-primary {
  color: #fdcc52;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.btn {
  border-radius: 0px;
  font-size: 1rem;
  font-family: 'Gotham Black';
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.btn:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.btn-xl {
  font-size: 16px;
  padding: 8px 20px;
}

.btn-outline {
  color: #000000;
  background-color: #fff115;
  font-family: 'GothamLight' !important;
}
.btn-outline:hover, .btn-outline:focus, .btn-outline:active, .btn-outline.active {
  color: #000000;
  background-color: #fff115;
}
.btn-black {
  background-color: #000000;
  color: #ffffff;
}
.btn-black:hover, .btn-black:focus, .btn-black:active, .btn-black.active {
  color: #ffffff;
  background-color: #000000;
}
.btn-promo-code {
   background: #e74c3c;
    box-shadow:         2px 3px 3px 0px  #d2d2d2;
    color: #ffffff !important;    
    width: 100%;
    font-size: 1rem;
    position: relative;
    height: 45px;
}

.btn-promo-code:focus, .btn-promo-code:active, .btn-promo-code.active {
    background: #e74c3c;   
    color: #ffffff;    
    border: 0px;    
    box-shadow: none;
}

.btn-promo-code:hover {
  background: #e74c3c; 
  color: #ffffff; 
  background: linear-gradient(-45deg, transparent 31px, #e74c3c 31px);
  border: 0px;    
  box-shadow: none;
}

.btn-promo-code:hover::after {
    content: "";
    position: absolute;
    width: 28px;
    height: 49px;
    border-bottom: 57px solid #cc3425;
    border-left: 31px solid transparent;
    -webkit-transform: rotate(21deg);
            transform: rotate(21deg);
    right: 10px;
    top: -7px;
}

.btn-promotions {
  background: #e74c3c;
  color: #ffffff; 
  border-bottom: 2px solid #e72f1c;  
 }

.btn-order-now {
  background: #fff115;
  color: #000000;    
  border-bottom: 2px solid #e7ca2b ;
}

.btn-howtolist {
  background: #fff115;
  color: #000000;    
  border-bottom: 2px solid #e7ca2b ;
  margin-left: 8px;
}
.btn-howtolist:focus {
  box-shadow: none;
  color: #000000;
}
.readmore-content {
   text-align: center;
   padding: 1rem;
}

/* Popup Product Details */
.popup-promocode-food-info {
    position: relative;
}

.popup-promocode-food-info img.promo-food-img {
    width: 100%; 
    max-height: 320px;
}

.popup-promocode-food-info button.close{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: #000000;
    color: #ffffff;
    text-shadow: 0 0px 0 #fff;
}

.popup-promocode-food-info .close:not(:disabled):not(.disabled):focus, .popup-promocode-food-info .close:not(:disabled):not(.disabled):hover {
    color: #ffffff;
}

.popup-promocode-food-info .promo-popup-top-section {
    float: left;
    width: 100%;
    position: relative;
}

.popup-promocode-food-info .tags-discount-block {
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 999;
    margin: 0px;
}

.popup-promocode-food-info .tags-discount-block .offer-tag {
    font-size: 2rem;
    color: #fff115;
    background-color: #181818;
    padding-top: 6px;
    margin: 0px;   
    border-top: 0px;
    text-align: center;
}

.promo-popup-middle-section {
   float: left;
   width: 100%;
   padding: 0px 1rem;
   padding-top: 40px;
}

.image-max-width {
	max-width : 1000px;
	text-align: center;
}

.promo-popup-middle-section h1 {
  font-family: 'Gotham Black';
  font-size: 2rem;
  margin-top: 5px;
}

.promo-popup-middle-section .expiry-date-info {
    font-family: 'Gotham Black';
    font-size: 0.9rem;
    background: #f0f0f0;
    border-radius: 7px;
    padding: 5px 21px;
    border: 2px solid #e8e8e8;
    text-transform: uppercase;
}

.promo-popup-middle-section p {
    margin-top: 2rem;   
    font-size: 1rem;
}

.promo-popup-bottom-section {
    float: left;
   width: 100%;
   padding: 2.5rem 4rem;
   text-align: center;
   background: #f0f0f0; 
}

.promo-popup-bottom-section p {
  font-size: 1rem;
  font-family: 'GothamLight';
}

.restaurant-name-redtxt {
  font-family: 'Gotham Black';
  font-size: 1rem;
  color: #e74c3c;
}

.promo-popup-bottom-section input {
  font-family: 'Gotham Black';
  font-size: 28px;
  text-align: center;
  width: 300px;
  border: 2px solid #e2e2e2;
  padding: 10px;
  margin-bottom: 10px;
  height: 60px;
}

.promo-popup-bottom-section .btn-promo-code {
  width: 220px;
  margin: 0 auto;
  font-size: 1.133rem;
}

.promo-popup-bottom-section .btn-promo-code img {
  margin-top: -3px;
  margin-right: 6px;
}
.popup-promocode.modal-footer {
  background-color:#fff115;

}
.popup-promocode.modal-footer a {
  display: block;
  text-align: center;
  font-family: 'Gotham Black';
  font-size: 1.133rem;
  color: #000000;
  width: 100%;
}
.restaurantdetails-header .resturant-sliders img{
   width: 100%;  
} 
.restaurantdetails-container {
  background: #f5f5f5;
  float: left;
  width: 100%;
}
.restaurant-title-block {
  float: left;
  width: 100%; 
  margin-top: -128px;
  margin-bottom: 20px; 
}
.restaurant-title-block .restaurant-title-left,
.restaurant-title-block .restaurant-title-right {
  float: left;
}
.restaurant-title-block .restaurant-title-right {
  padding-top: 90px;
  padding-left: 18px;
}
.restaurant-title-block .restaurant-title-right a { 
  color: #212222;
  text-decoration: none;
}
.restaurant-title-block .rest-logo-circle {
  width: 174px;
  height: 174px;
  border-radius: 50%;
  border: 6px solid #fff115;
  display: inline-block;
  padding-top: 26px;
  text-align: center;
  background: #ffff;
}
.restaurant-title-block .rest-logo-circle img{
  width: 130px;
  height: 100px;
  border-radius: 15px;
}
.restaurant-content-block-outer {
  float: left;
  width: 100%;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
}
.restaurant-content-block {   
  padding: 15px;
  word-break: break-word;
}
.restaurant-content-block p{
  font-size: 1rem;
  text-align: justify;
}
.restaurantdetails-container .promotions {
  float: left;
  width: 100%;
}
.restaurantdetails-right-block {
  float: left;
  width: 100%;
  min-height: 300px;
  border-radius: 10px;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #e8e8e8;
}
.restaurantdetails-right-block .order-btn-block .btn-order-now {
  display: block;
  margin-top: 1rem;
  padding: 1rem;
  height: 55px;
}
.restaurantdetails-right-block .order-btn-block .btn-order-now img {
  position: relative;
  top: -4px;
  right: 10px;
}   
.restaurantdetails-right-block .map-block img {
  margin-top: 1rem; 
  width: 100%;
}
.restaurantdetails-right-block .contacts-block ul {
  padding: 0.5rem 1rem;
}
.restaurantdetails-right-block  ul li{
  list-style: none;
  margin-top: 15px;
  word-break: break-word;
  display: flex;
  align-items: flex-start;
  font-weight: bold;
}

.restaurantdetails-right-block .contacts-block ul li .rest-info-left{
  width: 32px;
  height: 32px;
}
.restaurantdetails-right-block .contacts-block ul li .rest-info-right p {
  margin: 0;
  font-weight: normal;
}
.restaurantdetails-right-block .contacts-block ul li .rest-info-right span {
  font-weight: 300;
}
.restaurantdetails-right-block .contacts-block ul li .rest-info-right{
   width: calc(100% - 32px);
   padding-left: 0.5rem;
}
.restaurantdetails-right-bottom {  
  margin-top: 1rem;
  float: left;
  width: 100%;
  background: #ffffff;
  padding: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}
.restaurantdetails-right-bottom ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.restaurantdetails-right-bottom .restaurant-imgs {
  display: flex;
  flex-wrap: wrap;
}
.restaurantdetails-right-bottom .restaurant-imgs li {
  overflow: hidden;
  padding: 0px 5px 0 !important;
  width: auto;
  margin: 5px 0px;
}
.restaurantdetails-right-bottom .restaurant-imgs li a img {
  padding-right: 0px;
  height: 80px;
  width: 100%;
}
section.cms-pages.faq-page {
  float: left;
  width: 100%;
}
.faq-page h2 {
  margin-top: 1rem;
}
.faq-page .card-header {
  cursor: pointer;
  font-weight: bold;
}
.faq-page .card-text {
  font-size: 1rem;
}
@media (min-width: 768px) and (max-width: 1024px) {  
  .btn-promo-code {   
    font-size: 0.7rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {  
  section.promotions .card-resturant-food-outers .btn-promo-code img {
    width: 26px;
    height: 26px;    
  }
  .btn-promo-code {   
    font-size: 18px;
    height: 40px;
  }
  
}
@media (min-width: 320px) and (max-width: 480px) {  
  .btn-promo-code {   
    font-size: 1.2rem;
  }
  .restaurant-title-block h2 {
    font-size: 1.6rem;
    letter-spacing: 0px;
  }
  .restaurant-title-block .rest-logo-circle {
    width: 124px;
    height: 125px;
    border-radius: 50%;
    border: 6px solid #fff115;
    display: inline-block;
    padding-top: 7px;
    text-align: center;
    background: #ffff;
  }
  .restaurant-title-block .rest-logo-circle img {
    width: 80px;
    height: 80px;
    border-radius: 15px;
  }
  .restaurant-title-block .restaurant-title-right {
    padding-top: 0px; 
    padding-left: 18px;
  }
}


@media (max-width: 767px ) {
  section.apply-now-section .row:before {    
    width: 100%;
    left: 0px;
    height: 50%;
  }
  section.apply-now-section .row:after {   
    width: 100%;
    right: 0px;
    height: 50%;   
    bottom: 0;
  }
  section.apply-now-section .right-colm,
  section.apply-now-section .left-colm {
      padding: 0px 15px !important;
  }
  section.promotions .promotion-item-colm,
  section.restaurants .restaurant-item-colm{
      padding: 0px 0px !important;
  }
  .card-prmobudy-resturant-outers .btn {
      font-size: 1rem;
  }
  footer .social-colm h6 {
    margin-top: -6px;
    font-size: 12px;
    margin-right: 1px;   
  }
  .restaurant-title-block .restaurant-title-right {
    padding-top: 10px;
    padding-left: 18px;
  }
  

}


@media (max-width: 768px ) {
	  .section-heading {
	  font-size: 20px;
	  text-align : justify;
	  
  }
  
    .flex-row-column {
    flex-direction: column;
  }
  
  .padding-top-zero {
	   padding-top : 0px !important;
  }
  
  .padding-bottom-zero {
	  
	     padding-bottom : 0px !important;
		     display: block !important;
    width: 100% !important;
  }
}
@media (max-width: 991px) {
  .promo-popup-bottom-section {    
    padding: 2.5rem 2rem;   
  }
  .promo-popup-bottom-section input,
  .promo-popup-bottom-section .btn-promo-code {
     font-size: 1.5rem;
     width: 100%;
     height: 54px;
  }
  .promo-popup-bottom-section .btn-promo-code {
    height: 44px;
    padding: 2px;
  }
  .promo-popup-bottom-section .btn-promo-code img {
    margin-top: -6px;
    margin-right: 11px;
    width: 30px;
    height: 30px;
  }
  .promotions .container-fluid, 
  .restaurants .container-fluid {
    padding-right: 15px !important;
  }
}
@media (min-width: 992px) {
  .modal-dialog {
      max-width: 640px;     
  }
}
@media (max-width: 360px) {
  header.masthead .header-content h1 {
    font-size: 1rem;
  }
  .card-prmobudy-resturant-outers .btn,
  header.masthead .header-content p {
      font-size: 0.8rem;
  }
  .header-content .btn-xl {
    font-size: 12px;
  }
}
section.promotions .card-resturant-logo-img img {
  height: 54px;
}
.copied-code-msg {
    font-size: 16px;
    color: #45ca0e;
    font-weight: bold;
}   

.card-title > .res_name > a {
	color: "#222222";
} 
.prmobundy-rf-categories,
.prmobudy-rf-locations{
  word-break: break-all;
  display: flex;
  align-items: flex-start;
}

.prmobudy-resturant-right-colm .rest-info-left{
  width: 32px;
  height: 32px;
  text-align: center;
}

.prmobudy-resturant-right-colm .rest-info-right{
   width: calc(100% - 32px);
   padding-left: 0.5rem;
   font-weight: 600;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .card-prmobudy-resturant-outers.d-flex {
    flex-wrap: wrap;
  }
  .card-prmobudy-resturant-outers .btn {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  section.promotions .card-resturant-food-outers {
    padding: 1rem;
  }
  section.apply-now-section .apply-now-txt h2 {
    font-size: 1.5rem;    
  }
}

#mainNav {
  background: rgba(255,255,255,0.98) !important;
  padding-top: 7px;
  padding-bottom: 7px;
}

.promotions .container-fluid,
.restaurants .container-fluid{
  padding-right: 22px;
}

 
